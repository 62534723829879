import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Button, Input, InputProps, UncontrolledTooltip, FormGroup } from 'reactstrap'
import MapActionEditor from './MapActionEditor'
import Markdown from '../../../../common/Markdown'
import { mapObjectsData } from '../../../maps/Map/MapObjects/MapObjectsContainer/MapObjectsContainer'
import { selectors as mapSelectors } from '../../../../setup/tiles'
import { useSelector } from 'react-redux'
import { useFormContext } from '../../FormContext'
import { Label } from '../FormComponentWrapper'
import { getObjects } from '../../../../reducers/mapObjects'
import { Modal as AssetModal } from '../../../../common/AssetManager'
import FontAwesome from 'react-fontawesome'
import { toast } from 'react-toastify'
import {
	selectors,
	isScreenAction,
	useCreativeCanvasStations,
	useControlSet,
} from '../../../../reducers/simulationEditor'
import type { Collection } from '../../../../common/AssetManager/AssetManager'

import {
	fileRestrictionsByType,
	getMediaType,
	getNextActionIdsFromAction,
	isScreenSimplyReferenced,
} from '../../helpers/algorithms'
import { getTitle } from '../../actionDefinitions'
import type { DraggableItem } from '../../DragDrop/types'
import {
	actionDefinitions,
	createAction,
	type ActionId,
	type ActionReference,
} from '../../actionDefinitions'
import {
	EVENT_KEY_TO_STATION,
	handleJrPlusStationChange,
	handleJrStationChange,
} from '../../helpers/stations'
import { actionHelpers } from '../../PhaseCreator/helpers'
import { openOptionSelector, runWithConfirmationMessage } from '../../../../helpers/uiFunctions'
import { DELETE_CONFIRMATION_MESSAGE } from '../../helpers/constants'
import Target from '../../DragDrop/Target'
import { DraggableType } from '../../DragDrop/types'
import Select, { OptionProps, components } from 'react-select'
import { prettifyTypeEnum } from '../../../../helpers/functions'
import type { FileRestriction } from '../../helpers/algorithms'
import type {
	Image,
	Video,
	Audio,
	CreativeCanvasStationData,
	EventResult,
	ActivateStationActionWithStationData,
	Action,
	ActivateStationAction,
	TeacherTip,
} from '@mission.io/mission-toolkit/actions'
import {
	CREATIVE_CANVAS_STATION,
	SHARED_STATION_IDS,
	GENERIC_TEACHER_TIP_LOCATIONS,
} from '@mission.io/mission-toolkit/constants'
import { ReduxStore } from '../../../../types/ReduxStore'
import { EventResultKey } from '../../helpers/eventResults'
import MarkDownInput from '../../../../common/MarkdownInput'

export function NumberInput({
	value,
	onChange,
	...rest
}: {
	value: number
	onChange: (e: number) => unknown
} & Omit<InputProps, 'onChange' | 'value'>): JSX.Element {
	// Allow the user to change the input to empty string, while still only allowing numbers
	// out of the component through onChange
	const [useEmptyString, setUseEmptyString] = useState(false)
	return (
		<Input
			{...rest}
			type="number"
			value={useEmptyString ? '' : value.toString()}
			onChange={(e) => {
				setUseEmptyString(e.currentTarget.value === '')
				onChange(Number(e.currentTarget.value))
			}}
		/>
	)
}
export function StringInput({
	value,
	onChange,
	...rest
}: {
	onChange: (e: string) => unknown
} & Omit<InputProps, 'onChange'>): JSX.Element {
	return (
		<Input
			placeholder="Insert text here..."
			{...rest}
			value={value}
			type="text"
			onChange={(e) => onChange(e.currentTarget.value)}
		/>
	)
}

/**
 * An basic input for large strings, like descriptions. Uses a textarea instead of an input.
 */
export function LargeStringInput({
	value,
	onChange,
	...rest
}: {
	value: string
	onChange: (e: string) => void
} & InputProps): JSX.Element {
	return (
		<Input
			{...rest}
			value={value}
			type="textarea"
			placeholder="Insert text here..."
			onChange={(e) => onChange(e.currentTarget.value)}
		/>
	)
}
export function TimeInput({
	value,
	onChange,
}: {
	value: number
	onChange: (e: number) => void
}): JSX.Element {
	const totalSeconds = Math.floor(value / 1000)
	const totalMinutes = Math.floor(totalSeconds / 60)
	const milliseconds = value % 1000
	const seconds = totalSeconds % 60
	const minutes = totalMinutes % 60

	const convertToMs = ({
		min = minutes,
		sec = seconds,
		ms = milliseconds,
	}: {
		min?: number
		sec?: number
		ms?: number
	}): number => {
		return ms + sec * 1000 + min * 60 * 1000
	}

	return (
		<TimeInputStyle>
			<span>Minutes:</span>
			<NumberInput
				value={minutes}
				onChange={(newMinutes) =>
					onChange(
						convertToMs({
							min: newMinutes,
						})
					)
				}
				min={0}
			/>
			<span>Seconds:</span>
			<NumberInput
				value={seconds}
				onChange={(newSeconds) =>
					onChange(
						convertToMs({
							sec: newSeconds,
						})
					)
				}
				min={0}
			/>
			<span>Milliseconds:</span>
			<NumberInput
				value={milliseconds}
				onChange={(newMilliseconds) =>
					onChange(
						convertToMs({
							ms: newMilliseconds,
						})
					)
				}
				min={0}
			/>
		</TimeInputStyle>
	)
}
const TimeInputStyle = styled.div`
	display: flex;
	span {
		margin: 0px 4px;
	}
`
export function BooleanInput({
	value,
	onChange,
}: {
	value: boolean
	onChange: (e: boolean) => unknown
}): JSX.Element {
	return (
		<StyledCheck
			type="checkbox"
			checked={value}
			onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.checked)}
		/>
	)
}
const StyledCheck = styled(Input)`
	position: inherit !important;
	margin: auto 4px !important;
`

/**
 * @param props
 * @param props.canEditMap - Whether the map can be edited. If false, the user will only be able to see the static map.
 * @param props.showMap - Whether the map should be shown. Defaults to true.
 */
export function MapSelector({
	value,
	onChange,
	onSelectObject,
	actionType,
	showHidden = true,
	disabled = false,
	canEditMap = true,
	showMap = true,
}: {
	value: string | null | undefined
	onChange: (e: string | null | undefined) => void
	onSelectObject?: (arg0: () => void) => () => void
	actionType?: string
	showHidden?: boolean
	disabled?: boolean
	canEditMap?: boolean
	showMap?: boolean
}): JSX.Element {
	const { currentActionId, push } = useFormContext()
	return (
		<div>
			{!value && actionType === 'MAP_CHANGE' && (
				<ExtraMessage>
					Since no map is selected, this action will close the current map
				</ExtraMessage>
			)}
			<MapActionEditor
				value={value}
				disabled={disabled}
				onChange={onChange}
				onObjectClick={(objectId, mapId) => {
					if (onSelectObject) {
						onSelectObject(() => {
							if (currentActionId) {
								push({
									objectId,
									mapId: mapId,
									id: currentActionId,
								})
							}
						})()
					}
				}}
				canCreateMap={actionType === 'MAP_CHANGE'}
				canEditMap={canEditMap}
				showMap={showMap}
				showHidden={showHidden}
			/>
		</div>
	)
}
export function MapObjectSelector({
	value,
	onChange,
	mapId,
	disabled,
}: {
	value: string
	onChange: (e: string) => void
	mapId: string
	disabled?: boolean
}): JSX.Element {
	const maps = useSelector(mapSelectors.maps.store)
	const map = maps[mapId]
	const mapObjects = useSelector(getObjects)
	const [objects, setObjects] = useState<
		| Array<{
				id: string
				name: string
		  }>
		| null
		| undefined
	>(null)
	useEffect(() => {
		if (map && mapObjects) {
			const tempObjects: Array<{ id: string; name: string }> = []
			mapObjectsData.forEach(({ type, title, key }) => {
				const mapObjectIds = map.objects[key]
				mapObjectIds.forEach((mapObjectId) => {
					const mapObject = mapObjects[key][mapObjectId]
					tempObjects.push({
						id: mapObjectId,
						// @ts-expect-error TS18048 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
						name: mapObject.name,
					})
				})
			})
			setObjects(tempObjects)
		}
	}, [map, mapObjects, setObjects])
	return (
		<Input
			type="select"
			disabled={disabled}
			onChange={(e) => onChange(e.currentTarget.value)}
			value={value}>
			{objects &&
				objects.map((object) => (
					<option
						key={object.id}
						value={object.id}
						onChange={(e) => onChange(e.currentTarget.value)}>
						{object.name}
					</option>
				))}
			<option value="">None</option>
		</Input>
	)
}
export const ExtraMessage = styled.span`
	font-style: italic;
	line-height: 1.5;
`

export function UrlInput({
	value,
	onChange,
	restrictions,
	collection = 'automatedSimulationMedia',
	allowDelete = true,
}: {
	value: string | null | undefined
	onChange: (e: string | null | undefined) => void
	restrictions: FileRestriction
	collection?: Collection
	allowDelete?: boolean
}): JSX.Element {
	const [modalIsOpen, setModalIsOpen] = useState(false)

	const toggleModal = () => {
		setModalIsOpen((state) => !state)
	}
	const mediaType = value ? getMediaType(value) : null
	return (
		<div>
			<UrlInputContainer $valueExists={Boolean(value)}>
				<Button
					color="primary"
					size="sm"
					css={`
						max-width: fit-content;
					`}
					onClick={() => toggleModal()}>
					Choose Media
				</Button>
				{value && (
					<>
						{mediaType === 'IMAGE' ? (
							<img
								src={value}
								alt=""
								css="max-width: 100px; max-height: 100px; justify-self: center;"
								title={value}
							/>
						) : mediaType === 'VIDEO' ? (
							<video src={value} css="max-height: 100px; justify-self: center;" />
						) : (
							<Url>{value}</Url>
						)}
						{allowDelete && (
							<Button
								className="ml-2"
								color="danger"
								size="sm"
								onClick={() => {
									onChange(null)
								}}>
								<FontAwesome name="trash" />
							</Button>
						)}
					</>
				)}
			</UrlInputContainer>
			<AssetModal
				collection={collection}
				isOpen={modalIsOpen}
				onClose={toggleModal}
				onFileClick={(file) => {
					toggleModal()
					onChange(file.url)
				}}
				restrictions={restrictions}
			/>
		</div>
	)
}

type MediaInputPropsGeneric<T> = {
	value: Readonly<T> | null
	onChange: (newValue: T | null) => unknown
}

type MediaInputProps = (
	| (MediaInputPropsGeneric<Image> & { mediaType: 'IMAGE' })
	| (MediaInputPropsGeneric<Video> & { mediaType: 'VIDEO' })
	| (MediaInputPropsGeneric<Image | Video> & { mediaType: 'VIDEO_OR_IMAGE' })
	| (MediaInputPropsGeneric<Image | Video | Audio> & { mediaType: 'VIDEO_OR_IMAGE_OR_AUDIO' })
) & {
	collection: Collection
	allowDelete?: boolean
}

/**
 * An input component for selecting a url when the media also includes a type field, e.g.
 * {
 *   type: 'IMAGE',
 *   url: 'url.goes.here.com'
 * }
 *
 * Supports all media types defined in `MediaInputProps`
 */
export function MediaInput({ allowDelete, collection, ...props }: MediaInputProps): JSX.Element {
	return (
		<UrlInput
			allowDelete={allowDelete}
			collection={collection}
			restrictions={fileRestrictionsByType[props.mediaType]}
			value={props.value?.url}
			onChange={(newUrl: string | null | undefined) => {
				if (!newUrl) {
					props.onChange(null)
					return
				}

				if (props.mediaType === 'IMAGE') {
					props.onChange({ type: props.mediaType, url: newUrl })
					return
				}
				if (props.mediaType === 'VIDEO') {
					props.onChange({ type: props.mediaType, url: newUrl })
					return
				}

				if (props.mediaType === 'VIDEO_OR_IMAGE') {
					const typeOfNewMedia = getMediaType(newUrl)
					if (typeOfNewMedia === 'IMAGE' || typeOfNewMedia === 'VIDEO') {
						props.onChange({ type: typeOfNewMedia, url: newUrl })
						return
					}

					throw new Error(
						'It should be impossible to select a file that is not an image or a video'
					)
				}
				if (props.mediaType === 'VIDEO_OR_IMAGE_OR_AUDIO') {
					const typeOfNewMedia = getMediaType(newUrl)
					if (
						typeOfNewMedia === 'IMAGE' ||
						typeOfNewMedia === 'VIDEO' ||
						typeOfNewMedia === 'AUDIO'
					) {
						props.onChange({ type: typeOfNewMedia, url: newUrl })
						return
					}
					throw new Error(
						'It should be impossible to select a file that is not an image, video, or audio'
					)
				}

				throw new Error('Unsupported mediaType in MediaInput')
			}}
		/>
	)
}

const Url = styled.div`
	overflow: hidden;
`
const UrlInputContainer = styled.div<{ $valueExists?: boolean }>`
	display: grid;
	grid-template-columns: ${({ $valueExists = false }) => ($valueExists ? '30% 1fr auto' : 'auto')};

	grid-column-gap: 10px;
	height: auto;
	align-items: center;
`

/**
 * Creates a select input where the options are the values in `values`.
 */
export function getSelectInput<T extends string>(
	values: T[]
): React.ComponentType<{
	value: T
	onChange: (newValue: T) => unknown
}> {
	return function SelectInput({
		value,
		onChange,
		disabled,
		...props
	}: {
		value: T
		onChange: (arg0: T) => unknown
		disabled?: boolean
	}) {
		return (
			<Select<{ label: string; value: T }>
				{...props}
				value={{
					value,
					label: prettifyTypeEnum(value),
				}}
				onChange={(e) => {
					if (!e) return
					onChange(e.value)
				}}
				isDisabled={disabled}
				options={values.map((value) => ({
					value,
					label: prettifyTypeEnum(value),
				}))}
			/>
		)
	}
}
export type EventResultChangeProps = {
	type: 'EVENT_RESULT'
	newAction?: Action<string> | ActionReference
	deletedActionId?: ActionId
	modifiedEventResult: EventResult<string>
	optionIndex?: number
	issueIndex?: number
	eventType?: 'success' | 'fail'
}
export type EventResultInputProps = {
	value: EventResult<string>
	onChange: (arg0: EventResultChangeProps) => unknown
	label?: string
	actionId: ActionId | 'MAP'
	actions?: ReadonlyArray<Action<string> | ActionReference>
	onLeave: (arg0: (arg0: boolean) => void) => () => void
}

/**
 * This component is used to edit an event result array. The onChange function is a unique onChange that expects a EventResultChangeProps object.
 */
export function EventResultInput({
	value,
	onChange,
	actions,
	label,
	actionId,
	onLeave,
}: EventResultInputProps): JSX.Element {
	const { id: simulationId } = useParams<{ id: string }>()
	const { push } = useFormContext()
	const simulationActions = useSelector((state: ReduxStore) =>
		selectors.getActionsFromIds(state, value)
	)
	const allActions = useSelector(selectors.getActions)
	const initialScreenActionId = useSelector(selectors.getInitialScreenId)
	const isJunior = useSelector(selectors.isJunior)

	const shouldConfirmRemoval = (deletedActionId: ActionId) => {
		if (!allActions) return false

		if (isScreenAction(allActions[deletedActionId])) {
			if (!label) {
				throw new Error('A label is required for screen actions')
			}

			if (
				isScreenSimplyReferenced(
					deletedActionId,
					actionId === 'MAP'
						? 'MAP'
						: {
								id: actionId,
								eventKey: label as EventResultKey,
						  },
					allActions,
					initialScreenActionId
				)
			) {
				return false
			}
		}

		return getNextActionIdsFromAction(deletedActionId, allActions).length > 0
	}

	const totalActions: Action<string>[] = actions
		? [
				...simulationActions,
				...actions.filter((action): action is Action<string> => {
					return action.type !== 'SCREEN_REFERENCE' ? value.includes(action._id) : false
				}),
		  ]
		: simulationActions

	/**
	 * This function determines whether adding the given item should replace another action or not.
	 * I.E.: a user attempts to add a second screen action to an event result.
	 * If an action should be replaced, then this function will return an object with a
	 * confirmation message to the user and an index of the action to be replaced.
	 *
	 * @param {DraggableItem} item
	 */
	const addingActionShouldReplaceOtherAction = (
		item: DraggableItem
	):
		| {
				message: string
				indexToRemove: number
		  }
		| null
		| undefined => {
		const screenAction: Action<string> | null | undefined = totalActions.find((action) =>
			isScreenAction(action)
		)
		if (!screenAction) return null
		const indexOfScreenAction = totalActions.findIndex((action) => screenAction._id === action._id)

		if (
			indexOfScreenAction > -1 &&
			(item.type === 'SCREEN_TYPE' || item.meta.type === 'SCREEN_REFERENCE') &&
			item.meta.type !== 'VIDEO' &&
			item.meta.type !== 'IMAGE'
		) {
			// They are trying to add a screen type item that WILL be a screen action (video and image types are first rendered as overlays)
			return {
				message: `You are already using
				'${getTitle(screenAction)}' as the next screen for this event. Would you like to replace it with ${
					item.title
				}?`,
				indexToRemove: indexOfScreenAction,
			}
		}
	}

	const removeItem = (index: number) => {
		const deletedActionId = value[index]
		// @ts-expect-error TS2345 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
		const showConfirmation = shouldConfirmRemoval(deletedActionId)

		const onConfirm = () => {
			const newArray = value.slice()
			newArray.splice(index, 1)
			onChange({
				type: 'EVENT_RESULT',
				deletedActionId: deletedActionId,
				modifiedEventResult: newArray,
			})
		}

		if (showConfirmation) {
			runWithConfirmationMessage(DELETE_CONFIRMATION_MESSAGE, onConfirm)
		} else onConfirm()
	}

	return (
		<div>
			<Target
				onPlacement={(item) => {
					const itemValue: DraggableItem = item.item

					if (totalActions.some((value) => value._id === itemValue.id)) {
						toast.warn('Oops! You already added that action here')
						return
					}

					const runOnChange = ({
						indexToRemove,
					}: {
						indexToRemove?: number
					} = {}) => {
						const changeProps: Omit<EventResultChangeProps, 'modifiedEventResult'> = {
							type: 'EVENT_RESULT',
						}
						const eventResult = value.slice()

						if (typeof indexToRemove === 'number' && indexToRemove > -1) {
							eventResult.splice(indexToRemove, 1)
							changeProps.deletedActionId = value[indexToRemove]
						}

						const addScreenReference = (id: string) => {
							onChange({
								...changeProps,
								modifiedEventResult: [...eventResult, id],
								newAction: {
									type: 'SCREEN_REFERENCE',
									reference: id,
								},
							})
						}

						if (itemValue.meta.type === 'SCREEN_REFERENCE') {
							addScreenReference(itemValue.meta.referencedId)
						} else if (itemValue.id) {
							const id = itemValue.id

							const moveAction = () => {
								onChange({ ...changeProps, modifiedEventResult: [...eventResult, id] })
							}

							if (itemValue.type === DraggableType.SCREEN_TYPE) {
								openOptionSelector(
									`Would you like to move this screen change to the new position, or leave the screen where it is and jump to it from the new position?`,
									[
										['Move Screen', moveAction],
										['Add Jump to Screen', () => addScreenReference(id)],
									]
								)
							} else {
								moveAction()
							}
						} else {
							const newAction = createAction(itemValue.meta, simulationId, isJunior)

							if (newAction) {
								onChange({
									...changeProps,
									newAction,
									modifiedEventResult: [...eventResult, newAction._id],
								})
							}
						}
					}

					const replacementData = addingActionShouldReplaceOtherAction(itemValue)

					if (replacementData) {
						const { message, indexToRemove } = replacementData
						runWithConfirmationMessage(
							message,
							() => {
								// @ts-expect-error TS2345 SUPPRESS ERRORS FOR NEW OPTION noUncheckedIndexedAccess
								const showConfirmation = shouldConfirmRemoval(value[indexToRemove])

								if (showConfirmation) {
									runWithConfirmationMessage(DELETE_CONFIRMATION_MESSAGE, () =>
										runOnChange({
											indexToRemove,
										})
									)
								} else {
									runOnChange({
										indexToRemove,
									})
								}
							},
							'Replace',
							'INFO'
						)
					} else {
						runOnChange()
					}
				}}>
				<ActionBox>
					{totalActions.length > 0 ? (
						<ActionsContainer>
							{totalActions.map((action: Action<string>, index) => {
								return (
									<Card
										key={`${action.type}_${index}`}
										action={action}
										onClick={() => {
											const index = simulationActions.findIndex(
												(tempAction) => action._id === tempAction._id
											)

											if (index === -1) {
												toast.warn('Save form, then events can be modified')
												return
											}

											const leave = onLeave((isSaved: boolean) => {
												if (!isSaved) {
													// The check above to see if `action` exists in `simulationActions` makes it so isSaved will always be true
													toast.warn(
														`Attempted to edit ${action.type} action before saving its parent. This should never happen. Please notify the Mission.io engineering team`
													)
													return
												}

												push({
													id: action._id,
													graph: false,
												})
											})
											leave()
										}}>
										{getTitle(action)}
										<Icon
											onClick={(e) => {
												e.stopPropagation()
												removeItem(index)
											}}
											name="times"
										/>
									</Card>
								)
							})}
						</ActionsContainer>
					) : (
						<div>Drag Actions Here</div>
					)}
				</ActionBox>
			</Target>
			{actionId === 'MAP' &&
				!totalActions.find((action) => action.type === 'ACTIVATE_STATION') &&
				label &&
				label !== 'onScan' && (
					<Button
						onClick={() => {
							const newAction: ActivateStationAction<string> =
								actionDefinitions.ACTIVATE_STATION.createAction({
									simulationId,
									isJunior,
								}) as ActivateStationAction<string>

							if (newAction) {
								const stationChange = isJunior ? handleJrStationChange : handleJrPlusStationChange
								newAction.stationData = stationChange(
									// @ts-expect-error stationData will have the correct properties based on the label passed in
									newAction.stationData,
									EVENT_KEY_TO_STATION[label as keyof typeof EVENT_KEY_TO_STATION]
								)

								if (newAction.stationData.stationId === SHARED_STATION_IDS.CREATIVE_CANVAS) {
									return
								}

								newAction.stationData.onComplete = value
								const changeProps = {
									type: 'EVENT_RESULT' as const,
									newAction,
									modifiedEventResult: [newAction._id],
								}
								onChange(changeProps)
							}
						}}
						size="sm"
						outline
						css="margin-top: 8px;">
						Compress into Activate Station Action
					</Button>
				)}
		</div>
	)
}

/**
 * An EventResultInput with a description above it.
 */
export const EventResultInputWithDescription = (
	description: string
): ((props: EventResultInputProps) => JSX.Element) => {
	return function EventResultInputWithDescription(props: EventResultInputProps) {
		return (
			<DescriptionColumn>
				<div>{description}</div>
				<EventResultInput {...props} />
			</DescriptionColumn>
		)
	}
}
const DescriptionColumn = styled.div`
	display: block;
`
export const Icon = styled(FontAwesome)`
	margin: 0 0 0 auto;
	cursor: pointer;
`
const Card = styled.div<{ action: Action<string> }>`
	background-color: ${({ action }) => actionHelpers.getColor(action)};
	padding: 8px;
	margin: 7px;
	width: 31%;
	border-radius: 5px;
	color: white;
	overflow: hidden;
	font-size: 0.75rem;
	text-align: center;
	display: grid;
	grid-template-columns: 85% 1fr;
	grid-column-gap: 8px;
	cursor: pointer;
`
const ActionsContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`
const ActionBox = styled.div`
	background-color: #f0f0f0;
	border-radius: 5px;
	height: auto;
	width: 100%;
	padding: 10px;
`

/**
 * A custom option for the CreativeCanvasSelector. Makes it easier for the user to identify the
 * creative canvas station they want
 */
export function CreativeCanvasSelectorOption<IsMulti extends boolean>(
	props: OptionProps<CreativeCanvasSelectorOptionType & { type?: string }, IsMulti>
): ReactNode {
	if (!props.data.creativeCanvasAction) return null
	const creativeCanvasAction: NonNullable<
		CreativeCanvasSelectorOptionType['creativeCanvasAction']
	> = props.data.creativeCanvasAction
	const stationData = creativeCanvasAction.stationData
	const issues = 'issues' in stationData ? stationData.issues : [stationData.issue]
	return (
		<components.Option {...props}>
			<b>
				{stationData.variant === CREATIVE_CANVAS_STATION.VARIANT.GENERAL_ISSUE
					? 'General Issue Canvas'
					: `Issue Per Team Canvas with ${issues.length} issue${issues.length !== 1 ? 's' : ''}`}
			</b>
			<br />
			<ul css="margin-bottom: 0;">
				{issues.map((issue) => (
					<li key={issue._id}>{issue.prompt}</li>
				))}
			</ul>
		</components.Option>
	)
}

export type CreativeCanvasSelectorOptionType = {
	value: string
	label?: ReactNode
	creativeCanvasAction: ActivateStationActionWithStationData<
		CreativeCanvasStationData<string>,
		string
	> | null
}

/**
 * A select component to select from all creative canvas stations in the simulation.
 * @param props
 * @param props.value The current value. Either a station id or the empty string
 * @param props.onChange Called with the id of the newly selected station
 */
export function CreativeCanvasSelector({
	value,
	onChange,
}: {
	value: string
	onChange: (actionId: string) => unknown
}): ReactNode {
	const { push } = useFormContext()

	const creativeCanvasStations = useCreativeCanvasStations()

	const options = creativeCanvasStations.map((action) => ({
		value: action._id,
		creativeCanvasAction: action,
	}))

	const selectedOption = options.find((option) => option.value === value)

	return (
		<>
			<Select<CreativeCanvasSelectorOptionType>
				value={
					selectedOption
						? {
								...selectedOption,
								label: (
									<CreativeCanvasLabel creativeCanvasAction={selectedOption.creativeCanvasAction} />
								),
						  }
						: null
				}
				onChange={(option) => {
					option && onChange(option.value)
				}}
				options={options}
				components={{ Option: CreativeCanvasSelectorOption }}
			/>
			{value && (
				<div css="display: flex; justify-content: flex-end; ">
					<Button
						color="link"
						onClick={() => {
							push({ id: value })
						}}>
						Go to Canvas Action
					</Button>
				</div>
			)}
		</>
	)
}

/**
 * A label to display in a select component for a creative canvas station. This is the label to use when the canvas is selected, not to
 * be confused with the label to use in the options list.
 */
export function CreativeCanvasLabel({
	creativeCanvasAction,
}: {
	creativeCanvasAction: ActivateStationActionWithStationData<
		CreativeCanvasStationData<string>,
		string
	>
}): ReactNode {
	const issuePrompts =
		creativeCanvasAction.stationData.variant === CREATIVE_CANVAS_STATION.VARIANT.GENERAL_ISSUE
			? [creativeCanvasAction.stationData.issue?.prompt ?? '']
			: creativeCanvasAction.stationData.issues?.map(({ prompt }) => prompt) ?? []

	return (
		<div>
			<b>
				{creativeCanvasAction.stationData.variant === CREATIVE_CANVAS_STATION.VARIANT.GENERAL_ISSUE
					? 'General Issue Canvas'
					: `Issue Per Team Canvas with ${issuePrompts.length} issue${
							issuePrompts.length !== 1 ? 's' : ''
					  }`}
			</b>
			{issuePrompts.length ? <> ({issuePrompts[0]})</> : null}
		</div>
	)
}

/**
 * Allows editing an array of `TeacherTip`s
 * @param props.value The current value of the teacher tips array
 * @param props.onChange Called with the new array of teacher tips
 */
export function TeacherTipsEditor({
	value: teacherTips,
	onChange,
	rootAction,
}: {
	value: Array<TeacherTip<string>>
	onChange: (e: Array<TeacherTip<string>>) => void
	rootAction?: Action<string>
}) {
	const controlSet = useControlSet()

	let markdownForGenericTeacherTip: string | null | undefined = null
	const definition = rootAction ? actionDefinitions[rootAction.type] : null
	if (definition?.allowCustomTeacherTips) {
		markdownForGenericTeacherTip = definition?.getMarkdownForGenericTeacherTip?.(
			// @ts-expect-error rootAction will have the correct properties based on the type of the root action
			rootAction,
			controlSet
		)
	}

	const hideGeneric = teacherTips?.some(
		(tip) => tip.genericTeacherTipLocation === GENERIC_TEACHER_TIP_LOCATIONS.HIDDEN
	)

	const renderTip = (tip: TeacherTip<string>, index: number) => {
		const setTipField = (field: keyof TeacherTip<string>, newValue: string) => {
			onChange(teacherTips.map((t, i) => (i === index ? { ...t, [field]: newValue } : t)))
		}
		const showBeforeGeneric =
			tip.genericTeacherTipLocation === GENERIC_TEACHER_TIP_LOCATIONS.AFTER_THIS ||
			tip.genericTeacherTipLocation === undefined
		return (
			<div key={index} className="flex gap-2 mb-2">
				<StringInput
					value={tip.title}
					onChange={(str) => setTipField('title', str)}
					placeholder="Teacher Tip"
					className="size-fit"
				/>
				<MarkDownInput
					value={tip.text}
					onChange={(newText) => {
						setTipField('text', newText)
					}}
					disabledComponents={['a', 'img']}
					className="flex-grow"
				/>
				{markdownForGenericTeacherTip && !hideGeneric && (
					<>
						<Button
							id={`generic-tip-place-${index}`}
							color="primary"
							outline
							className="max-h-10"
							onClick={() => {
								if (showBeforeGeneric) {
									setTipField(
										'genericTeacherTipLocation',
										GENERIC_TEACHER_TIP_LOCATIONS.BEFORE_THIS
									)
								} else {
									setTipField('genericTeacherTipLocation', GENERIC_TEACHER_TIP_LOCATIONS.AFTER_THIS)
								}
							}}>
							<FontAwesome name={showBeforeGeneric ? 'arrow-down' : 'arrow-up'} />
						</Button>
						<UncontrolledTooltip placement="top" target={`generic-tip-place-${index}`}>
							{showBeforeGeneric ? 'Move Below Generic Tip' : 'Move Above Generic Tip'}
						</UncontrolledTooltip>
					</>
				)}

				<Button
					color="danger"
					outline
					className="max-h-10"
					onClick={() => {
						onChange(teacherTips.filter((_, i) => i !== index))
					}}>
					<FontAwesome name="trash" />
				</Button>
			</div>
		)
	}

	return (
		<>
			{teacherTips.map((tip, index) =>
				tip.genericTeacherTipLocation !== GENERIC_TEACHER_TIP_LOCATIONS.BEFORE_THIS
					? renderTip(tip, index)
					: null
			)}
			<Button
				size="sm"
				className="float-end"
				outline
				onClick={() => {
					onChange([...teacherTips, { text: '' }])
				}}>
				Add Custom Tip
			</Button>
			{markdownForGenericTeacherTip && (
				<>
					{teacherTips?.length ? (
						<FormGroup switch>
							<Label check className="!inline-block">
								Include Generic Tip
							</Label>

							<Input
								type="switch"
								checked={!hideGeneric}
								onChange={(event) => {
									const newTips = teacherTips.map((t) => ({
										...t,
										genericTeacherTipLocation: event.target.checked
											? undefined
											: GENERIC_TEACHER_TIP_LOCATIONS.HIDDEN,
									}))
									onChange(newTips)
								}}
							/>
						</FormGroup>
					) : null}
					{!hideGeneric && (
						<div className="border-l-2 border-neutral pl-4 my-2">
							<Markdown>{markdownForGenericTeacherTip}</Markdown>
						</div>
					)}
				</>
			)}

			{teacherTips.map((tip, index) =>
				tip.genericTeacherTipLocation === GENERIC_TEACHER_TIP_LOCATIONS.BEFORE_THIS
					? renderTip(tip, index)
					: null
			)}
		</>
	)
}
